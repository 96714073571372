<template>
    <CCol class="col-12 stageSection">
        <ConfirmDialog ref="confirmDialog"/>
        <CCol v-if="isEdit" class="col-12">
            <h5>Edit Field "Stage"</h5>
        </CCol>
        <CCol v-if="!isEdit" class="col-12 mt-2">
            <div class="inline-block w-100 text-end">
                <span class="pointer btnStageEdit" @click="handleEdit">
                    <CIcon class="icon" name="cilPen"/>
                    <span class="additionBtn"> Edit </span>
                </span>
            </div>

            <CNSelect v-model="propVal" :options="stages" :error="error" :invalid="!!error"
                      :disabled="!permissions.editOrCreate" label="Project Stage" required @change="$emit('change')"/>
        </CCol>
        <CCol v-if="isEdit" class="my-3">
            <h5>Option List</h5>
            <CCol>
                <CRow v-for="(stage, key) in options" :key="key" :xs="{ cols: 2, gutterX: 0 }" class="my-3">
                    <CCol class="col-10">
                        <CNInput v-model="stage.name" :label="'Option ' + (key+1)" :invalid="!!validationErrors[key]"
                                 :error="validationErrors[key]" @blur="handleBlurOption(key)"/>
                    </CCol>
                    <CCol class="col-2 mx-auto mt-2 text-center">
                        <CButton v-if="options.length > 1" class="deleteItemList text-error" variant="ghost"
                                 @click="handleDelete(key)">
                            <CIcon name="cilX"/>
                        </CButton>
                    </CCol>
                </CRow>

                <CRow :xs="{ cols: 2, gutterX: 0 }" class="mt-2">
                    <CCol class="col-10">
                        <CNInput v-model="newStage" :label="'Option ' + incrementStage" @blur="handleBlur"/>
                    </CCol>
                    <CCol class="col-2 mx-auto m-auto text-center">
                        <CButton class="deleteItemList" variant="ghost" disabled>
                            <CIcon name="cilX"/>
                        </CButton>
                    </CCol>
                </CRow>

                <CRow :xs="{ cols: 2 }" class="mt-3">
                    <CCol class="col-12 col-sm-auto mb-3 mb-md-auto">
                        <CButton class="w-100" :disabled="loading" color="primary" @click="handleSave">
                            Save
                        </CButton>
                    </CCol>
                    <CCol class="col-12 col-sm-auto">
                        <CButton class="w-100" :disabled="loading" variant="outline" color="primary"
                                 @click="handleCancel">
                            Cancel
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
        </CCol>
    </CCol>
</template>

<script>

import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {isEqual} from '@/utils/helper'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {mapGetters} from 'vuex'
import {permissionError} from '@/services/Notify/Toasts'

export default {
    name: 'Stages',
    components: {ConfirmDialog},
    inject: ['toast'],
    props: {
        error: {type: String, default: () => ''},
        stages: {
            type: Array, default: () => {
            }
        },
        modelValue: {type: [String, Number], default: () => null},
    },
    emits: ['update:modelValue', 'change', 'updated'],
    data() {
        return {
            loading: false,
            isEdit: false,
            newStage: '',
            options: [],
            origin: [],
            validationErrors: {},
        }
    },
    computed: {
        ...mapGetters({
            permissions: 'projectPermissions',
        }),
        incrementStage() {
            return this.options.length + 1
        },
        propVal: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },
    },
    watch: {
        options: {
            handler() {
                this.setRules()
            },
            deep: true,
        },
        isEdit(val) {
            this.$emit('edit', val);
        }
    },
    methods: {
        handleBlurOption(index) {
            this.validationErrors[index] = this.validator.validate(index, this.options[index]['name'])
        },
        setRules() {
            let baseRules = {}
            let options = this.options.map((opt) => opt.name)
            if (this.options.length) {
                options.forEach((el, index) => {
                    baseRules[index] = [
                        rules.required,
                        rules.strMax(255),
                        rules.unique(options.filter((o, i) => i !== index)),
                    ]
                })
            }

            this.validator = validator(baseRules)
        },
        handleEdit() {
            if (!this.permissions.editOrCreate) {
                this.$notify(permissionError)
            } else {
                this.options = this.$deepClone(this.stages)
                this.origin = this.$deepClone(this.options)
                this.isEdit = true
            }
        },
        async handleCancel() {
            if (!isEqual(this.options, this.origin)) {
                await this.$refs.confirmDialog
                    .confirm({text: this.$t('messages.unsaved'), cancelText: 'Cancel'})
                    .then((res) => {
                        if (res) this.handleClose()
                    })
            } else {
                this.handleClose()
            }
        },
        handleClose() {
            this.options = []
            this.isEdit = false
        },
        handleDelete(index) {
            this.options.splice(index, 1)
        },
        handleBlur() {
            if (this.newStage) {
                this.options.push({name: this.newStage, value: null})
                this.newStage = ''
            }
        },
        handleSave() {
            const validationResult = this.validator.validateAll(this.options.map((el) => el.name))
            if (!validationResult.hasErrors) {
                this.loading = true
                const formData = this.options
                    .filter((el) => el.name && el.name.trim().length)
                    .map((el) => ({name: el.name, id: el.value}))

                this.$http.projects
                    .editStage({options: formData})
                    .then(({data}) => {
                        this.$emit('updated')
                        this.handleClose()
                        this.toast('success', data.message)
                    })
                    .finally(() => (this.loading = false))
            } else {
                this.validationErrors = this.$deepClone(validationResult.validationErrors)
            }
        },
    },
}
</script>
