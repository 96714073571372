<template>
  <div :key="key" class="projectDetails">
    <CCard v-if="Object.keys(form).length" style="min-height: 75vh">
      <CCardBody style="padding: 24px;">
        <ConfirmDialog ref="confirmDialog" />
        <CCol class="col-12 col-lg-12 col-xl-8">
          <BuilderSteps :step="form.step" />
        </CCol>
        <CRow class="flex-column-reverse flex-lg-row mt-3">
          <CCol class="col-12 col-lg-6 col-xl-8">
            <CRow :xs="{ cols: 1, gutter: 4 }" :sm="{ cols: 2, gutter: 4 }" :lg="{ cols: 1, gutter: 4 }" :xl="{ cols: 2, gutter: 4 }" class="mb-4 mt-3 mt-lg-auto">
              <GeneralInformationBlock ref="generalInformationBlock" :form="form" />
              <CustomFieldBlock ref="generalInformationCustom" :fields="sectionsCustomFields" :form-fields="form.custom_fields" type="General Information" @update="fetchSectionsCustomFields" @delete="deleteCustomField" />
              <AddCustomField :section-id="generalSectionId" @update="fetchSectionsCustomFields" />
              <CCol class="col-12 p-0"><CDivider /></CCol>
            </CRow>
            <CRow :xs="{ cols: 2, gutter: 4 }" class="mb-4">
              <ProjectLocationBlock ref="projectLocationBlock" :form="form.location" :states="states" />
              <CustomFieldBlock ref="projectLocationCustom" :fields="sectionsCustomFields" :form-fields="form.custom_fields" type="Project Location" @update="fetchSectionsCustomFields" @delete="deleteCustomField" />
              <AddCustomField :section-id="locationSectionId" @update="fetchSectionsCustomFields" />
              <CCol class="col-12 p-0"><CDivider /></CCol>
            </CRow>
            <CRow :xs="{ cols: 1, gutter: 4 }" :sm="{ cols: 2, gutter: 4 }" :lg="{ cols: 1, gutter: 4 }" :xl="{ cols: 2, gutter: 4 }">
              <OwnerBlock ref="ownerBlock" :form="form.owner" :states="states" />
              <CustomFieldBlock ref="ownerCustom" :fields="sectionsCustomFields" :form-fields="form.custom_fields" type="Owner Information" @update="fetchSectionsCustomFields" @delete="deleteCustomField"  />
              <AddCustomField :section-id="ownerSectionId" @update="fetchSectionsCustomFields" />
            </CRow>

            <FixedBottomPanel
              :buttons="fixedBottomPanelButtons"
              :disabled-delete-button="!form.id || loading"
              @deleteHandler="deleteProject"
            />

          </CCol>
          <CCol class="col-12 col-lg-6 col-xl-4">
            <CRow :xs="{ cols: 1, gutter: 3 }" class="mt-0 pb-3 timeLineBlock">
              <CCol><h5>Timeline</h5></CCol>
              <CCol class="col-12 p-0"><CDivider /></CCol>
              <CCol class="col-12">
                <CNInputDate
                  v-model="form.awarded_date"
                  label="Awarded Date"
                  :disabled="disabled"
                  required
                  :error="validationErrors.awarded_date"
                  :invalid="!!validationErrors.awarded_date"
                  @blur="validateField('awarded_date')"
                  @touchField="validateField('awarded_date')"
                />
              </CCol>
              <CCol class="col-12">
                <CNInputDate
                  v-model="form.estimated_start_date"
                  :disabled="disabled"
                  label="Estimated Starting Date"
                  :end="form.estimated_end_date"
                  :error="validationErrors.estimated_start_date"
                  :invalid="!!validationErrors.estimated_start_date"
                  @blur="validateField('estimated_start_date')"
                />
              </CCol>
              <CCol class="col-12">
                <CNInputDate
                  v-model="form.estimated_end_date"
                  :disabled="disabled"
                  label="Estimated Completion Date"
                  :start="form.estimated_start_date"
                  :error="validationErrors.estimated_end_date"
                  :invalid="!!validationErrors.estimated_end_date"
                  @blur="validateField('estimated_end_date')"
                />
              </CCol>
              <CCol class="col-12 p-0"><CDivider /></CCol>
              <CCol class="col-12">
                <Stages
                  v-model="form.stage_id"
                  :disabled="disabled"
                  :stages="stages"
                  :error="validationErrors.stage_id"
                  @updated="fetchStage"
                  @edit="onStagesEditToggle"
                  @change="validateField('stage_id')"
                />
                <div v-if="stagesInEdit && saveDirty" class="alert alert-danger mt-3" role="alert">
                  Confirm changes before saving
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
