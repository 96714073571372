<template>
  <CCol class="col-12">
    <CRow class="justify-content-between">
      <CCol class="col-12 col-sm-auto">
        <h5>Project Location</h5>
      </CCol>
      <CCol>
        <CCol class="col-12 col-sm-auto text-end">
          <span class="editManually pointer" @click="isGoogleApi = !isGoogleApi">
            {{ title }}
          </span>
        </CCol>
      </CCol>
    </CRow>
  </CCol>
  <CCol v-if="isGoogleApi" class="col-12">
    <GoogleSearch :address="form.full_address" :disabled="disabled" required @update="handleChangeAddress" />
  </CCol>
  <CCol v-if="!isGoogleApi" class="col-12">
    <CNInput
      v-model="form.address"
      label="Street"
      :disabled="disabled"
      required
      :error="validationErrors.address"
      :invalid="!!validationErrors.address"
      @blur="validateField('address')"
    />
  </CCol>

  <CCol v-if="!isGoogleApi" class="col-12">
    <CRow :xs="{ gutterY: 3 }">
      <CCol class="col-12 col-xl-5 col-xxl-5">
        <CNInput
          v-model="form.city"
          label="City" required
          :disabled="disabled"
          :error="validationErrors.city"
          :invalid="!!validationErrors.city"
          @blur="validateField('city')"
        />
      </CCol>
      <CCol class="col-12 col-xl-4 col-xxl-5">
        <CNInput
          v-model="form.zip"
          label="Zip Code"
          :disabled="disabled"
          :mask="CONF.MASK_FORMAT_ZIP"
          required :error="validationErrors.zip"
          :invalid="!!validationErrors.zip"
          @blur="validateField('zip')"
        />
      </CCol>
      <CCol class="col-12 col-xl-3 col-xxl-2">
        <CNSelect
          v-model="form.state_id"
          :disabled="disabled"
          :options="states"
          :error="validationErrors.state_id"
          :invalid="!!validationErrors.state_id"
          label="State"
          required
          @change="validateField('state_id')"
        />
      </CCol>
    </CRow>
  </CCol>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import CONF from '@/utils/constants/config'
import GoogleSearch from './GoogleSearch'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'ProjectLocationBlock',
  components: { GoogleSearch },
  props: {
    form: { type: Object, default: () => {} },
    states: { type: Array, default: () => [] },
  },
  data() {
    return {
      CONF,
      validationErrors: {},
      options: [],
      isGoogleApi: false,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    title() {
      return this.isGoogleApi ? 'Enter Manually' : 'Go to Google API'
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setRules()
    })
  },
  methods: {
    handleShow() {
      // !this.permissions.editOrCreate
      //   ? this.$notify(permissionError)
      //   : this.isGoogleApi = true
      this.isGoogleApi = true
    },
    showGoogleSearch() {
      this.isGoogleApi = false
    },
    handleChangeAddress(data) {
      this.form.state_id = data.state_id
      this.form.zip = data.post_code
      this.form.address = data.street
      this.form.city = data.city
      this.form.county = data.county
      this.form.full_address = data.full_address

      this.validateAll()
    },
    validateField(f) {
      this.validationErrors[f] = this.validator.validate(f, this.form[f])
    },
    validateAll() {
      const validationResult = this.validator.validateAll(this.form)
      this.validationErrors = this.$deepClone(validationResult.validationErrors)
      if (validationResult.hasErrors) this.isGoogleApi = false
      return !validationResult.hasErrors
    },
    setRules() {
      this.validator = validator({
        address: [rules.required, rules.strMax(255)],
        zip: [rules.required, rules.strMin(5)],
        state_id: [rules.required],
        city: [rules.required, rules.strMax(255)],
      })
    },
  },
}
</script>
<!--315 5th Ave S Suite 200, Seattle, WA 98104-->
<style scoped>

</style>
