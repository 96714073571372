<template>
    <CCol class="col-12 owners">
        <ConfirmDialog ref="confirmDialog"/>
        <CRow :xs="{ cols: 1 }" :xl="{ cols: 2 }">
            <CCol class="col-12">
                <h5>Edit "Owner Type" field</h5>
            </CCol>

            <CCol v-for="(stage, key) in options" :key="key" :xs="{ cols: 2, gutterX: 0 }" class="my-3">
                <CRow>
                    <CCol class="col-9 col-sm-10">
                        <CNInput v-model="stage.name" :label="'Option ' + (key+1)" :invalid="!!validationErrors[key]"
                                 :error="validationErrors[key]" @blur="handleBlurOption(key)"/>
                    </CCol>
                    <CCol class="col-3 col-sm-2 mx-auto mt-2 text-center">
                        <CButton v-if="options.length > 1" class="deleteItemList text-error" variant="ghost"
                                 @click="handleDelete(key)">
                            <CIcon name="cilX"/>
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>

            <CCol class="my-3">
                <CRow>
                    <CCol class="col-9 col-sm-10">
                        <CNInput v-model="newType" :label="'Option ' + incrementStage" @blur="handleBlur"/>
                    </CCol>
                    <CCol class="col-3 col-sm-2 m-auto text-center">
                        <CButton class="deleteItemList" variant="ghost" disabled>
                            <CIcon name="cilX"/>
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>

            <CCol class="col-12">
                <CRow :xs="{ cols: 1 }" class="mt-3">
                    <CCol class="col-12 col-sm-auto mb-3 mb-md-auto">
                        <CButton class="w-100" :disabled="loading" color="primary" @click="handleSave">
                            Save
                        </CButton>
                    </CCol>
                    <CCol class="col-12 col-sm-auto">
                        <CButton class="w-100" :disabled="loading" variant="outline" color="primary"
                                 @click="handleCancel">
                            Cancel
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </CCol>
</template>

<script>
import CNInput from '@/components/ui/CNInput/CNInput'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {isEqual} from '@/utils/helper'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'

export default {
    name: 'Owners',
    components: {CNInput, ConfirmDialog},
    inject: ['toast'],
    props: {
        error: {type: String, default: () => ''},
        owners: {
            type: Array, default: () => {
            }
        },
    },
    emits: ['updated', 'close'],
    data() {
        return {
            validationErrors: {},
            loading: false,
            newType: '',
            options: [],
            origin: [],
        }
    },
    computed: {
        incrementStage() {
            return this.options.length + 1
        },
    },
    watch: {
        options: {
            handler() {
                this.setRules()
            },
            deep: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.handleShow()
            this.setRules()
        })
    },
    methods: {
        setRules() {
            let baseRules = {}
            let options = this.options.map((opt) => opt.name)
            if (this.options.length) {
                options.forEach((el, index) => {
                    baseRules[index] = [
                        rules.required,
                        rules.strMax(255),
                        rules.unique(options.filter((o, i) => i !== index)),
                    ]
                })
            }

            this.validator = validator(baseRules)
        },
        handleBlurOption(index) {
            this.validationErrors[index] = this.validator.validate(index, this.options[index]['name'])
        },
        handleShow() {
            this.options = this.$deepClone(this.owners)
            this.origin = this.$deepClone(this.options)
        },
        async handleCancel() {
            if (!isEqual(this.options, this.origin)) {
                await this.$refs.confirmDialog
                    .confirm({text: this.$t('messages.unsaved'), cancelText: 'Cancel'})
                    .then((res) => {
                        if (res) this.handleClose()
                    })
            } else {
                this.handleClose()
            }
        },
        handleClose() {
            this.options = []
            this.$emit('close')
        },
        handleDelete(index) {
            this.options.splice(index, 1)
        },
        handleBlur() {
            if (this.newType) {
                this.options.push({name: this.newType, value: null})
                this.newType = ''
                this.setRules()
                this.handleBlurOption(this.options.length - 1)
            }
        },
        handleSave() {
            const validationResult = this.validator.validateAll(this.options.map((el) => el.name))
            if (!validationResult.hasErrors) {
                this.loading = true
                const formData = this.options
                    .filter((el) => el.name && el.name.trim().length)
                    .map((el) => ({name: el.name, id: el.value}))

                this.$http.projects
                    .editOwnerType({options: formData})
                    .then(({data}) => {
                        this.$emit('updated')
                        this.handleClose()
                        this.toast('success', data.message)
                    })
                    .finally(() => (this.loading = false))
            } else {
                this.validationErrors = this.$deepClone(validationResult.validationErrors)
            }
        },
    },
}
</script>
