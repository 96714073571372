<template>
  <div v-if="form.id || !$route.params.id">
    <ConfirmDialog ref="confirmDialog" />
    <Owner
      v-if="isOwner"
      ref="owner"
      :key="key"
      :project="form"
      @update="getProject"
    />
  </div>
</template>

<script>
import Owner from './Owner'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
  name: 'ProjectDetails',
  components: { Owner, ConfirmDialog },
  async beforeRouteLeave() {
    if (this.$refs.owner && this.$refs.owner.isEditForm) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            return true
          } else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  data() {
    return {
      key: 0,
      project: {},
      origin: {},
      form: {
        name: '',
        internal_number: '',
        subsidiary_company_id: null,
        manager_id: null,
        signer_id: null,
        abbreviation: '',
        issuing_office_id: null,
        contact_number: '',
        task_assignee_id: null,
        description: '',
        awarded_date: '',
        estimated_start_date: '',
        estimated_end_date: '',
        stage_id: null,
        step: undefined,
        location: {
          state_id: null,
          city: '',
          address: '',
          zip: '',
          street: '',
        },
        owner: {
          owner_name: '',
          owner_type_id: null,
          owner_state_id: null,
          city: '',
          owner_address: '',
          owner_zip: '',
          timezone: '',
          poc_name: '',
          poc_email: '',
          poc_phone: '',
          owner_fax: '',
        },
        custom_fields: {},
      },
    }
  },
  computed: {
    isOwner() {
      let checkCompany =
        this.$store.getters.user?.company?.id === this.form.company_id
      let checkType =
        this.$store.getters.user?.company?.type !== 'sub_contractor'
      return checkType && (checkCompany || !this.$route.params.id)
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.params.id) this.getProject()
    })
  },
  methods: {
    update() {
      if (this.$route.params.id) this.getProject()
      else this.key++
    },
    async getProject() {
      await this.$http.projects
        .getProjectInfo(this.$route.params.id)
        .then((res) => {
          this.form = res.data.data
          this.form.custom_fields = {}
          res.data.data.custom_field_values.forEach((el) => {
            this.form.custom_fields[el.custom_field_id] = {
              field_id: el.custom_field_id,
              values: el.selected_values,
            }
          })

          if (!this.form.subsidiary_company_id) {
            this.form.subsidiary_company_id =
              this.$store.getters.user.company.id
          }
          delete this.form.custom_field_values
          this.key++
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.$router.push({ name: 'PermissionsError' })
          }
        })
    },
  },
}
</script>

<style scoped></style>
