<template>
  <CCol class="col-12">
    <CNInput
      v-model="form.name"
      :disabled="disabled"
      label="Project Name"
      required
      :error="validationErrors.name"
      :invalid="!!validationErrors.name"
      @blur="validateField('name')"
    />
  </CCol>
  <CCol class="">
    <CNSelect
      v-model="form.subsidiary_company_id"
      :disabled="disabled"
      :options="companies"
      :error="validationErrors.subsidiary_company_id"
      :invalid="!!validationErrors.subsidiary_company_id"
      label="Select Company"
      required
      @change="validateField('subsidiary_company_id')"
    />
  </CCol>
  <CCol class="">
    <CNInput
      v-model="form.internal_number"
      label="Project Number"
      :disabled="disabled"
      required
      :error="validationErrors.internal_number"
      :invalid="!!validationErrors.internal_number"
      @blur="validateField('internal_number')"
    />
  </CCol>
  <CCol class="">
    <CNSelect
      v-model="form.manager_id"
      :options="primaryContacts"
      :error="validationErrors.manager_id"
      :invalid="!!validationErrors.manager_id"
      label="Primary Contact"
      :disabled="disabled"
      required
      search
      @change="validateField('manager_id')"
    />
  </CCol>
  <CCol class="">
    <CNSelect
      v-model="form.task_assignee_id"
      :options="signers"
      :error="validationErrors.task_assignee_id"
      :invalid="!!validationErrors.task_assignee_id"
      label="Task Assignee"
      required
      :disabled="disabled"
      search
      @change="validateField('task_assignee_id')"
    />
  </CCol>
  <CCol class="">
    <CNInput
      v-model="form.abbreviation"
      label="Project Abbreviation"
      :disabled="disabled"
      :error="validationErrors.abbreviation"
      :invalid="!!validationErrors.abbreviation"
      @blur="validateField('abbreviation')"
    />
  </CCol>
  <CCol class="">
    <CNSelect
      v-model="form.issuing_office_id"
      :options="offices"
      :disabled="disabled"
      :error="validationErrors.issuing_office_id"
      :invalid="!!validationErrors.issuing_office_id"
      label="Issuing Office"
      :clearable="true"
      @change="validateField('issuing_office_id')"
    />
  </CCol>
  <CCol class="">
    <CNInput
      v-model="form.contact_number"
      :mask="CONF.MASK_FORMAT_PHONE"
      :disabled="disabled"
      label="Phone Number"
      :error="validationErrors.contact_number"
      :invalid="!!validationErrors.contact_number"
      @blur="validateField('contact_number')"
    />
  </CCol>
  <CCol class="">
    <CNInput
      v-model="form.external_number"
      :disabled="disabled"
      label="External Number"
      :error="validationErrors.external_number"
      :invalid="!!validationErrors.external_number"
      @blur="validateField('external_number')"
    />
  </CCol>
  <CCol class="">
    <CNInput
      v-model="projectValue"
      :disabled="disabled"
      mask="money"
      :mask-money="money"
      label="Project value (USD)"
      :error="validationErrors.value"
      :invalid="!!validationErrors.value"
      @blur="validateField('value')"
    />
  </CCol>
  <CCol class="">
    <CNSelect
      v-model="form.license_id"
      :disabled="disabled"
      :options="companyLicenses"
      :error="validationErrors.license_id"
      :invalid="!!validationErrors.license_id"
      label="License"
      :clearable="true"
      @change="validateField('license_id')"
    />
  </CCol>
  <CCol class="col-12">
    <CNTextarea
      v-model="form.description"
      label="Description"
      :disabled="disabled"
      :error="validationErrors.description"
      :invalid="!!validationErrors.description"
      @blur="validateField('description')"
    />
  </CCol>

</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import CONF from '@/utils/constants/config'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'GeneralInformationBlock',
  props: {
    form: { type: Object, default: () => {} },
  },
  data() {
    return {
      CONF,
      validationErrors: {},
      companies: [],
      primaryContacts: [],
      signers: [],
      licenses: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
      },
    }
  },
  computed: {
    ...mapGetters({
      offices: 'offices',
      permissions: 'projectPermissions',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    offices() {
      return this.$store.getters.offices
        .map((el) => ({ value: el.id, name: `${el.name} (${el.address_format})` }))
    },
    projectValue: {
      get() {
        return this.form.value
      },
      set(newValue) {
        this.form.value = newValue.replace(' (USD)', '').replaceAll(',', '')
      },
    },
    companyLicenses() {
      let filterId = this.form.subsidiary_company_id === this.$store.getters.user.company.id
          ? null
          : this.form.subsidiary_company_id

      return this.licenses.filter((el) => el.subsidiary_company_id === filterId)
        .map((item) => ({ value: item.id, name: item.number }))
    },
  },
  watch: {
    'form.subsidiary_company_id': function () {
      this.form.license_id = null
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchReferenceBook()
      this.setRules()
    })
  },
  methods: {
    validateAll() {
      const validationResult = this.validator.validateAll(this.form)
      this.validationErrors = this.$deepClone(validationResult.validationErrors)
      return !validationResult.hasErrors
    },
    fetchReferenceBook() {
      this.$http.common
        .fetchReferenceBook({ params: { only: 'project_detail_companies,company_primary_contacts,company_default_signers_or_assigneers,company_licenses' } })
        .then(({ data }) => {
          this.companies = data.data.project_detail_companies
            .map((item) => ({ value: item.id, name: item.name }))
          this.primaryContacts = data.data.company_primary_contacts
            .map((item) => ({ value: item.id, name: item.full_name }))
          this.signers = data.data.company_default_signers_or_assigneers
            .map((item) => ({ value: item.id, name: item.full_name }))
          this.licenses = data.data.company_licenses

          // this.licenses = data.data.company_licenses
          //   .map((item) => ({ value: item.id, name: item.number }))
        })
    },
    validateField(f) {
      this.validationErrors[f] = this.validator.validate(f, this.form[f])
    },
    setRules() {
      this.validator = validator({
        name: [rules.required, rules.strMax(255)],
        subsidiary_company_id: [rules.required, rules.number],
        internal_number: [rules.required, rules.strMax(255)],
        value: [() => (this.form.value ? 0 : -1), rules.numMin(0), rules.numMax(1000000000000)],
        external_number: [() => (this.form.external_number ? 0 : -1), rules.strMax(255)],
        abbreviation: [() => (this.form.abbreviation ? 0 : -1), rules.strMax(10)],
        // contact_number: [rules.strMax(255)],
        manager_id: [rules.required, rules.number],
        task_assignee_id: [rules.required, rules.number],
        // issuing_office_id: [rules.number],
        // description: [() => (this.form.description ? 0 : -1), rules.strMax(10000)],
      })
    },
  }
}
</script>

<style scoped>

</style>
