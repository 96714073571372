<template>
    <CCol :key="key" class="col-12">
        <CNSelect v-model="form.place_id" :options="options" :loading="loading" :error="validationErrors.place_id"
                  :invalid="!!validationErrors.place_id" label="Address" :required="required" :disabled="disabled"
                  searchable @search="search" @change="handleChangeAddress"/>
    </CCol>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'

export default {
    name: 'GoogleSearch',
    inject: ['toast'],
    props: {
        disabled: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
        address: {type: String, default: ''},
    },
    emits: ['update'],
    data() {
        return {
            key: 0,
            loading: false,
            validationErrors: {},
            options: [],
            form: {
                place_id: undefined,
            },
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (!!this.address) {
                this.options = [{name: this.address, value: 1}]
                this.form = {place_id: 1}
                this.key++
            }

            this.setRules()
        })
    },
    methods: {
        search(val) {
            if (val && val.length <= 255) {
                this.loading = true
                this.$http.location.searchAddress({params: {q: val}}).then(({data}) => {
                    this.options = data.data.map((item) => ({name: item.full_address, value: item.place_id}))
                })
                    .catch(({response}) => this.toast('success', response.data.message))
                    .finally(() => (this.loading = false))
            } else {
                this.options = []
            }
        },
        validate() {
            this.validationErrors['place_id'] = this.validator.validate('place_id', this.form['place_id'])
        },
        handleChangeAddress() {
            this.validate()
            if (this.form.place_id && typeof this.form.place_id === 'string') {
                this.loading = true
                this.$http.location.getPlace({params: this.form}).then(({data}) => {
                    if (data.response_status === 404) {
                        this.toast('error', data.message)
                        return
                    }
                    this.$emit('update', data.data)
                })
                    .finally(() => (this.loading = false))
            }
        },
        setRules() {
            this.validator = validator({
                place_id: [this.required ? rules.required : rules.nullable],
            })
        },
    }
}
</script>

<style scoped>

</style>
